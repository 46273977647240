<template>
  <div>
    <div style="width: 100%">
      <img style="width: 100%" src="./img/顶部背景.png" alt="" />
    </div>

    <div class="bottomCss">
      <div class="xiangqing">
        <!-- 关键参数 -->
        <div class="canshu">
          <div class="csimg">
            <img style="width: 100%;" :src=this.canshuData alt="" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
     
      canshuData:require("./img/门禁控制器.jpg"),

    };
  },
  methods: {
  },
  mounted() {
  },
};
</script>

<style scoped>
.bottomCss {
  width: 100%;
  height: 100%;
  background: #f2f5f8;
  display: flex;
  justify-content: center;
}
.xiangqing {
  width: 69%;
  /* height: 3147px; */
  background: #ffffff;
  margin-top: 4%;
  margin-bottom: 4%;
}
.imgCss {
  font-size: 38px;
  font-family: Microsoft YaHei-Bold, Microsoft YaHei;
  font-weight: bold;
  color: #000000;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 54px;
}
.datu {
  width: 500px;
  height: 500px;
  margin-bottom: 16px;
  margin-top: 42px;
}
.tupailie {
  display: flex;
  flex-direction: column;
}
.xtpl {
  display: flex;
  justify-content: space-evenly;
}
.xioatu {
  width: 110px;
  height: 110px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 12px 12px 12px 12px;
}
.xioatu:hover {
  width: 110px;
  height: 110px;
  border: 2px solid rgba(9, 64, 253, 1);
  border-radius: 12px 12px 12px 12px;
}
.jianjie {
  margin: 0px 64px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.youdian {
  margin: 0px 64px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.canshu {
  margin: 0px 64px 64px 64px;
}
.biaoqian {
  margin-right: 12px;
  height: 36px;
  background: rgba(9, 64, 253, 0.1);
  font-size: 16px;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  font-weight: 400;
  border-radius: 6px 6px 6px 6px;
  color: #0940fd;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
}
.ydlb {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 36px;
}
.ydnr {
  width: 50%;
  font-size: 20px;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.9);
  display: flex;
  align-items: center;
  margin-top: 24px;
}
.ydnr::before {
  content: "";
  display: flex;
  width: 9px;
  height: 9px;
  background: #0940fd;
  border-radius: 50%;
  margin-right: 16px;
}
.csimg {
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>